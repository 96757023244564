import React, { useState } from 'react';
import { SectionHeader } from '../../modules/common/components/section-header';
import { Seo } from '../../modules/common/components/seo';
import { DashboardLayout } from '../../modules/layout/components/dashboard-layout';
import { CommunitySection } from '../../modules/common/components/community-section';

import './index.scss';
import { StaticImage } from 'gatsby-plugin-image';
import {
  Accordion,
  Button,
  ButtonGroup,
  Card,
  Col,
  Row
} from 'react-bootstrap';
import { CategoryCard } from '../../modules/cs/main/components/category-card';
import { Box } from '../../modules/common/components/box';
import { HSRCharacter } from '../../modules/hsr/common/components/hsr-character';
import { HSRLightCone } from '../../modules/hsr/common/components/hsr-light-cone';
import Countdown from 'react-countdown';

const HSRIndexPage: React.FC = () => {
  const [currentRegion, setCurrentRegion] = useState('NA');
  const renderer = ({ days, hours, minutes, completed }) => {
    if (completed) {
      return <span className="time">Event ended</span>;
    } else {
      return (
        <span className="time">
          {days >= 1 ? (
            <>
              {days}d {hours}h
            </>
          ) : (
            <>
              {hours}h {minutes}m
            </>
          )}
        </span>
      );
    }
  };

  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const renderer2 = ({ days, hours, minutes, completed }) => {
    if (completed) {
      return <span className="time">Event started</span>;
    } else {
      return (
        <span className="time">
          {days >= 1 ? (
            <>
              {days}d {hours}h
            </>
          ) : (
            <>
              {hours}h {minutes}m
            </>
          )}
        </span>
      );
    }
  };
  return (
    <DashboardLayout className={'home-page hsr'} game="hsr">
      <div className="dashboard-hero">
        <h1>Prydwen.gg - Honkai: Star Rail wiki and database</h1>
        <h2>
          Prydwen.gg is a wiki and database for Honkai: Star Rail, a HoYoverse
          turn-based space fantasy RPG. Check out our guides, character reviews,
          tier list and more!
        </h2>
      </div>
      <div className="fuse-ad-placeholder" data-fuse="22844297256"></div>
      <SectionHeader title="Active codes" />
      <div className="codes">
        <Box isCentered>
          <p className="code">STARRAILGIFT</p>
          <p className="rewards">50 Stellar Jades + EXP materials</p>
          <p className="date">Released on 26.04.2023</p>
        </Box>
        <Box isCentered>
          <p className="code">KFWJG6L3N8M1V</p>
          <p className="rewards">Materials + Credits</p>
          <p className="date">Released on 16.10.2024</p>
        </Box>
        <Box isCentered>
          <p className="code">KFWJD7Y2G9S5H</p>
          <p className="rewards">Materials + Credits</p>
          <p className="date">Released on 23.10.2024</p>
        </Box>
        <Box isCentered>
          <p className="code">EB3KUYQW833P</p>
          <p className="rewards">50 Stellar Jades + EXP materials</p>
          <p className="date">Released on 23.10.2024</p>
        </Box>
        <Box isCentered>
          <p className="code">3A23UG9FR24T</p>
          <p className="rewards">50 Stellar Jades + EXP materials</p>
          <p className="date">Released on 05.11.2024</p>
        </Box>
      </div>
      {/* <span className="new">NEW!</span>{' '} */}
      <SectionHeader title={'Event Timeline'} />
      <Row xs={1} xl={2}>
        <Col>
          <div className="tracker-header">
            <h5>Current</h5>
            <ButtonGroup aria-label="region-switcher">
              <Button
                variant="secondary"
                className={`${currentRegion === 'NA' && 'active'}`}
                onClick={() => setCurrentRegion('NA')}
              >
                NA
              </Button>
              <Button
                variant="secondary"
                className={`${currentRegion === 'EU' && 'active'}`}
                onClick={() => setCurrentRegion('EU')}
              >
                EU
              </Button>
              <Button
                variant="secondary"
                className={`${currentRegion === 'Asia' && 'active'}`}
                onClick={() => setCurrentRegion('Asia')}
              >
                Asia
              </Button>
            </ButtonGroup>
          </div>
          <Accordion className="event-tracker">
            {/* <Accordion.Item className="realm" eventKey="444131">
              <Accordion.Header as="div">
                <div className="overlay"></div>
                <div className="event-name">Realm of the Strange</div>
                <div className="countdown">
                  {currentRegion === 'NA' && (
                    <Countdown date={1729242000000} renderer={renderer} />
                  )}
                  {currentRegion === 'EU' && (
                    <Countdown date={1729220400000} renderer={renderer} />
                  )}
                  {currentRegion === 'Asia' && (
                    <Countdown date={1729195200000} renderer={renderer} />
                  )}
                </div>
              </Accordion.Header>
              <Accordion.Body>
                <p className="duration">
                  <span>Event Duration</span>: 2024/10/11 04:00:00 – 2024/10/18
                  03:59:00 (server time)
                </p>
                <p className="description">
                  <span>Description</span>: During the event, the amount of
                  Relics obtained from the Cavern of Corrosion will be doubled.
                </p>
              </Accordion.Body>
            </Accordion.Item> */}
            {/* <Accordion.Item className="planar-fissure" eventKey="44412">
              <Accordion.Header as="div">
                <div className="overlay"></div>
                <div className="event-name">Planar Fissure</div>
                <div className="countdown">
                  {currentRegion === 'NA' && (
                    <Countdown date={1731488400000} renderer={renderer} />
                  )}
                  {currentRegion === 'EU' && (
                    <Countdown date={1731466800000} renderer={renderer} />
                  )}
                  {currentRegion === 'Asia' && (
                    <Countdown date={1731441600000} renderer={renderer} />
                  )}
                </div>
              </Accordion.Header>
              <Accordion.Body>
                <p className="duration">
                  <span>Event Duration</span>: 2024/11/06 04:00:00 – 2024/11/13
                  03:59:00 (server time)
                </p>
                <p className="description">
                  <span>Description</span>: During the event, Planar Ornaments
                  obtained from the immersion device will be doubled when
                  challenging the Simulated Universe. The number of daily double
                  reward opportunities is limited and can be viewed on the
                  Planar Fissure event page.
                </p>
              </Accordion.Body>
            </Accordion.Item> */}
            <Accordion.Item className="memory-of-chaos" eventKey="2241">
              <Accordion.Header as="div">
                <div className="overlay"></div>
                <div className="event-name">Memory of Chaos (2.5)</div>
                <div className="countdown">
                  {currentRegion === 'NA' && (
                    <Countdown date={1732528800000} renderer={renderer} />
                  )}
                  {currentRegion === 'EU' && (
                    <Countdown date={1732507200000} renderer={renderer} />
                  )}
                  {currentRegion === 'Asia' && (
                    <Countdown date={1732482000000} renderer={renderer} />
                  )}
                </div>
              </Accordion.Header>
              <Accordion.Body>
                <p className="duration">
                  <span>Event Duration</span>: 2024/10/14 04:00:00 – 2024/11/25
                  03:59:00 (server time)
                </p>
                <p className="description">
                  <span>Memory Turbulence</span>: Enemies have Exo-Toughness and
                  their actions are delayed by 30% after they are Weakness
                  Broken. At the same time, add 1 hit(s) to "Memory
                  Turbulence"'s Hits Per Action. If the target is an Elite enemy
                  or higher, then additionally add 2 hit(s), up to a max of 10
                  hits. At the start of every Cycle, every hit by the "Memory
                  Turbulence" causes 1 instance of DMG, dealing a set amount of
                  DMG to random enemy targets.
                </p>
              </Accordion.Body>
            </Accordion.Item>
            <Accordion.Item className="event-1-26" eventKey="25001">
              <Accordion.Header as="div">
                <div className="overlay"></div>
                <div className="event-name">Sound Hunt Ninjutsu</div>
                <div className="countdown">
                  {currentRegion === 'NA' && (
                    <Countdown date={1733133600000} renderer={renderer} />
                  )}
                  {currentRegion === 'EU' && (
                    <Countdown date={1733112000000} renderer={renderer} />
                  )}
                  {currentRegion === 'Asia' && (
                    <Countdown date={1733086800000} renderer={renderer} />
                  )}
                </div>
              </Accordion.Header>
              <Accordion.Body>
                <p className="duration">
                  <span>Event Duration</span>: 2024/10/25 12:00 - 2024/12/02
                  03:59:00 (server time)
                </p>
                <p className="description">
                  <span>Event description</span>: Take the role of band manager
                  at the music party, and complete performances to obtain
                  various rewards.
                </p>
              </Accordion.Body>
            </Accordion.Item>
            <Accordion.Item className="event-3-26" eventKey="25003">
              <Accordion.Header as="div">
                <div className="overlay"></div>
                <div className="event-name">Shadow of the Ranger</div>
                <div className="countdown">
                  {currentRegion === 'NA' && (
                    <Countdown date={1733133600000} renderer={renderer} />
                  )}
                  {currentRegion === 'EU' && (
                    <Countdown date={1733112000000} renderer={renderer} />
                  )}
                  {currentRegion === 'Asia' && (
                    <Countdown date={1733086800000} renderer={renderer} />
                  )}
                </div>
              </Accordion.Header>
              <Accordion.Body>
                <p className="duration">
                  <span>Event Duration</span>: 2024/11/13 04:00 - 2024/12/02
                  03:59:00 (server time)
                </p>
                <p className="description">
                  <span>Event description</span>: Assign characters to complete
                  the event assignments to obtain various rewards.
                </p>
              </Accordion.Body>
            </Accordion.Item>
            <Accordion.Item className="nameless-honor" eventKey="992">
              <Accordion.Header as="div">
                <div className="overlay"></div>
                <div className="event-name">Nameless Honor</div>
                <div className="countdown">
                  {currentRegion === 'NA' && (
                    <Countdown date={1733133600000} renderer={renderer} />
                  )}
                  {currentRegion === 'EU' && (
                    <Countdown date={1733112000000} renderer={renderer} />
                  )}
                  {currentRegion === 'Asia' && (
                    <Countdown date={1733086800000} renderer={renderer} />
                  )}
                </div>
              </Accordion.Header>
              <Accordion.Body>
                <p className="duration">
                  <span>Event Duration</span>: After 2.6 update - 2024/12/02
                  3:59:00 (server time)
                </p>
                <p className="description">
                  <span>Description</span>: During the event, Trailblazers can
                  increase the level of Nameless Honor to obtain various
                  rewards.
                </p>
              </Accordion.Body>
            </Accordion.Item>
            <Accordion.Item className="odyssey" eventKey="998">
              <Accordion.Header as="div">
                <div className="overlay"></div>
                <div className="event-name">Gift of Odyssey</div>
                <div className="countdown">
                  {currentRegion === 'NA' && (
                    <Countdown date={1733133600000} renderer={renderer} />
                  )}
                  {currentRegion === 'EU' && (
                    <Countdown date={1733112000000} renderer={renderer} />
                  )}
                  {currentRegion === 'Asia' && (
                    <Countdown date={1733086800000} renderer={renderer} />
                  )}
                </div>
              </Accordion.Header>
              <Accordion.Body>
                <p className="duration">
                  <span>Event Duration</span>: After 2.6 update - 2024/12/02
                  3:59:00 (server time)
                </p>
                <p className="description">
                  <span>Description</span>: Trailblazers can receive daily
                  rewards in the Travel Log each day after logging in to the
                  game. Rewards will be unlocked in sequential order according
                  to the accrued login days of the Trailblazer.
                </p>
              </Accordion.Body>
            </Accordion.Item>
            <Accordion.Item className="acheron" eventKey="2603">
              <Accordion.Header as="div">
                <div className="overlay"></div>
                <div className="event-name">The Duality of Mei</div>
                <div className="countdown">
                  {currentRegion === 'NA' && (
                    <Countdown date={1733256000000} renderer={renderer} />
                  )}
                  {currentRegion === 'EU' && (
                    <Countdown date={1733230800000} renderer={renderer} />
                  )}
                  {currentRegion === 'Asia' && (
                    <Countdown date={1733209200000} renderer={renderer} />
                  )}
                </div>
              </Accordion.Header>
              <Accordion.Body>
                <p className="duration">
                  <span>Event Duration</span>: 2024/11/13 12:00:00 - 2024/12/03
                  15:00:00 (server time)
                </p>
                <p className="featured">
                  Featured <span className="hsr-rar rar-5">5★</span> character:
                </p>
                <div className="featured-characters">
                  <Card className="avatar-card">
                    <HSRCharacter
                      slug="acheron"
                      mode="icon"
                      showIcon
                      enablePopover
                    />
                  </Card>
                </div>
                <p className="featured">
                  Featured <span className="hsr-rar rar-5">5★</span> Light Cone:
                </p>
                <div className="featured-cone">
                  <HSRLightCone mode="profile" name="Along the Passing Shore" />
                </div>
                <p className="featured">
                  Featured <span className="hsr-rar rar-4">4★</span> characters:
                </p>
                <div className="featured-characters">
                  <Card className="avatar-card">
                    <HSRCharacter
                      slug="march-7th"
                      mode="icon"
                      showIcon
                      enablePopover
                    />
                  </Card>
                  <Card className="avatar-card">
                    <HSRCharacter
                      slug="sampo"
                      mode="icon"
                      showIcon
                      enablePopover
                    />
                  </Card>
                  <Card className="avatar-card">
                    <HSRCharacter
                      slug="pela"
                      mode="icon"
                      showIcon
                      enablePopover
                    />
                  </Card>
                </div>
                <p className="featured">
                  Featured <span className="hsr-rar rar-4">4★</span> Light
                  Cones:
                </p>
                <div className="featured-cone">
                  <HSRLightCone mode="profile" name="Day One of My New Life" />
                  <HSRLightCone mode="profile" name="Boundless Choreo" />
                  <HSRLightCone mode="profile" name="Make the World Clamor" />
                </div>
              </Accordion.Body>
            </Accordion.Item>
            <Accordion.Item className="ave" eventKey="2604">
              <Accordion.Header as="div">
                <div className="overlay"></div>
                <div className="event-name">It's Gamba Time!</div>
                <div className="countdown">
                  {currentRegion === 'NA' && (
                    <Countdown date={1733256000000} renderer={renderer} />
                  )}
                  {currentRegion === 'EU' && (
                    <Countdown date={1733230800000} renderer={renderer} />
                  )}
                  {currentRegion === 'Asia' && (
                    <Countdown date={1733209200000} renderer={renderer} />
                  )}
                </div>
              </Accordion.Header>
              <Accordion.Body>
                <p className="duration">
                  <span>Event Duration</span>: 2024/11/13 12:00:00 - 2024/12/03
                  15:00:00 (server time)
                </p>
                <p className="featured">
                  Featured <span className="hsr-rar rar-5">5★</span> character:
                </p>
                <div className="featured-characters">
                  <Card className="avatar-card">
                    <HSRCharacter
                      slug="aventurine"
                      mode="icon"
                      showIcon
                      enablePopover
                    />
                  </Card>
                </div>
                <p className="featured">
                  Featured <span className="hsr-rar rar-5">5★</span> Light Cone:
                </p>
                <div className="featured-cone">
                  <HSRLightCone
                    mode="profile"
                    name="Inherently Unjust Destiny"
                  />
                </div>
                <p className="featured">
                  Featured <span className="hsr-rar rar-4">4★</span> characters:
                </p>
                <div className="featured-characters">
                  <Card className="avatar-card">
                    <HSRCharacter
                      slug="march-7th"
                      mode="icon"
                      showIcon
                      enablePopover
                    />
                  </Card>
                  <Card className="avatar-card">
                    <HSRCharacter
                      slug="sampo"
                      mode="icon"
                      showIcon
                      enablePopover
                    />
                  </Card>
                  <Card className="avatar-card">
                    <HSRCharacter
                      slug="pela"
                      mode="icon"
                      showIcon
                      enablePopover
                    />
                  </Card>
                </div>
                <p className="featured">
                  Featured <span className="hsr-rar rar-4">4★</span> Light
                  Cones:
                </p>
                <div className="featured-cone">
                  <HSRLightCone mode="profile" name="Day One of My New Life" />
                  <HSRLightCone mode="profile" name="Boundless Choreo" />
                  <HSRLightCone mode="profile" name="Make the World Clamor" />
                </div>
              </Accordion.Body>
            </Accordion.Item>
            <Accordion.Item className="apo" eventKey="2242">
              <Accordion.Header as="div">
                <div className="overlay"></div>
                <div className="event-name">Apocalyptic Shadow (2.6)</div>
                <div className="countdown">
                  {currentRegion === 'NA' && (
                    <Countdown date={1733738400000} renderer={renderer} />
                  )}
                  {currentRegion === 'EU' && (
                    <Countdown date={1733716800000} renderer={renderer} />
                  )}
                  {currentRegion === 'Asia' && (
                    <Countdown date={1733691600000} renderer={renderer} />
                  )}
                </div>
              </Accordion.Header>
              <Accordion.Body>
                <p className="duration">
                  <span>Event Duration</span>: 2024/10/28 04:00:00 – 2024/12/09
                  03:59:00 (server time)
                </p>
                <p className="description">
                  <span>Memory Turbulence</span>: When an enemy unit with
                  "Steadfast Safeguard" is Weakness Broken, recovers Skill
                  Points for all ally characters and activates their Ultimates.
                  Increases the Weakness Break DMG taken by all enemies by 20%,
                  and increases Follow-up ATK DMG taken by 20%.
                </p>
              </Accordion.Body>
            </Accordion.Item>
            <Accordion.Item className="pure-fiction" eventKey="2243">
              <Accordion.Header as="div">
                <div className="overlay"></div>
                <div className="event-name">Pure Fiction (2.6)</div>
                <div className="countdown">
                  {currentRegion === 'NA' && (
                    <Countdown date={1734948000000} renderer={renderer} />
                  )}
                  {currentRegion === 'EU' && (
                    <Countdown date={1734926400000} renderer={renderer} />
                  )}
                  {currentRegion === 'Asia' && (
                    <Countdown date={1734901200000} renderer={renderer} />
                  )}
                </div>
              </Accordion.Header>
              <Accordion.Body>
                <p className="duration">
                  <span>Event Duration</span>: 2024/11/11 04:00:00 – 2024/12/23
                  03:59:00 (server time)
                </p>
                <p className="description">
                  <span>Memory Turbulence</span>: Increases DoT taken by enemy
                  targets by 35%. Enemy targets that receive DoT have their DMG
                  dealt reduced by 35%. After they are attacked, their action is
                  Advanced Forward by 35%.
                </p>
              </Accordion.Body>
            </Accordion.Item>
          </Accordion>
        </Col>
        <Col>
          <div className="tracker-header">
            <h5>Upcoming</h5>
            <ButtonGroup aria-label="region-switcher">
              <Button
                variant="secondary"
                className={`${currentRegion === 'NA' && 'active'}`}
                onClick={() => setCurrentRegion('NA')}
              >
                NA
              </Button>
              <Button
                variant="secondary"
                className={`${currentRegion === 'EU' && 'active'}`}
                onClick={() => setCurrentRegion('EU')}
              >
                EU
              </Button>
              <Button
                variant="secondary"
                className={`${currentRegion === 'Asia' && 'active'}`}
                onClick={() => setCurrentRegion('Asia')}
              >
                Asia
              </Button>
            </ButtonGroup>
          </div>
          <Accordion className="event-tracker">
            <Accordion.Item className="garden" eventKey="445">
              <Accordion.Header as="div">
                <div className="overlay"></div>
                <div className="event-name">Garden of Plenty</div>
                <div className="countdown">
                  {currentRegion === 'NA' && (
                    <Countdown date={1732269600000} renderer={renderer} />
                  )}
                  {currentRegion === 'EU' && (
                    <Countdown date={1732248000000} renderer={renderer} />
                  )}
                  {currentRegion === 'Asia' && (
                    <Countdown date={1732222800000} renderer={renderer} />
                  )}
                </div>
              </Accordion.Header>
              <Accordion.Body>
                <p className="duration">
                  <span>Event Duration</span>: 2024/11/22 04:00:00 – 2024/11/29
                  04:00:00 (server time)
                </p>
                <p className="description">
                  <span>Description</span>: During the event, Golden and Crimson
                  Calyx will give double rewards.
                </p>
              </Accordion.Body>
            </Accordion.Item>
            <Accordion.Item className="memory-of-chaos" eventKey="2241">
              <Accordion.Header as="div">
                <div className="overlay"></div>
                <div className="event-name">Memory of Chaos (2.6)</div>
                <div className="countdown">
                  {currentRegion === 'NA' && (
                    <Countdown date={1732528800000} renderer={renderer2} />
                  )}
                  {currentRegion === 'EU' && (
                    <Countdown date={1732507200000} renderer={renderer2} />
                  )}
                  {currentRegion === 'Asia' && (
                    <Countdown date={1732482000000} renderer={renderer2} />
                  )}
                </div>
              </Accordion.Header>
              <Accordion.Body>
                <p className="duration">
                  <span>Event Duration</span>: 2024/11/25 04:00:00 – 2025/01/06
                  03:59:00 (server time)
                </p>
                <p className="description">
                  <span>Memory Turbulence</span>: When an enemy target's
                  Weakness is Broken, add 1 hits to "Memory Turbulence"'s Hits
                  Per Action. If the target is an Elite enemy or higher, then
                  additionally add 1 hits, up to a max increase of 15 hits. At
                  the start of every Cycle, every hit by the "Memory Turbulence"
                  deals 1 instance of a set amount of DMG to random enemy
                  targets, and the Hits Per Action is not reset.
                </p>
              </Accordion.Body>
            </Accordion.Item>
          </Accordion>
        </Col>
      </Row>
      <br />
      <SectionHeader title="Shortcuts" />
      <Row
        xs={1}
        md={2}
        lg={2}
        xxl={3}
        className="g-3 category-cards-container"
      >
        <CategoryCard
          title="Introduction to the game"
          link="/star-rail/guides/introduction-to-the-game"
          image={
            <StaticImage
              src="../../images/starrail/categories/category_introgame.png"
              alt="Introduction to the game"
            />
          }
        />
        <CategoryCard
          title="Reroll guide"
          link="/star-rail/guides/reroll"
          image={
            <StaticImage
              src="../../images/starrail/categories/category_rerollguide.jpg"
              alt="Reroll"
            />
          }
        />
        <CategoryCard
          title="Tier list"
          link="/star-rail/tier-list"
          image={
            <StaticImage
              src="../../images/starrail/categories/category_tierlist.png"
              alt="Tier list"
            />
          }
        />
        <CategoryCard
          title="Free characters"
          link="/star-rail/guides/free-characters"
          image={
            <StaticImage
              src="../../images/starrail/categories/category_freechars.png"
              alt="Free characters"
            />
          }
        />
        <CategoryCard
          title="Characters"
          link="/star-rail/characters"
          image={
            <StaticImage
              src="../../images/starrail/categories/category_characters.png"
              alt="Characters"
            />
          }
        />
        <CategoryCard
          title="Early progression tips"
          link="/star-rail/guides/early-progression"
          image={
            <StaticImage
              src="../../images/starrail/categories/category_earlyprog.png"
              alt="Early progression tips"
            />
          }
        />
        <CategoryCard
          title="Team building"
          link="/star-rail/guides/team-building"
          image={
            <StaticImage
              src="../../images/starrail/categories/category_team.png"
              alt="Team building"
            />
          }
        />
        <CategoryCard
          title="Light Cones"
          link="/star-rail/guides/light-cones"
          image={
            <StaticImage
              src="../../images/starrail/categories/category_cones_guide.png"
              alt="Light Cones"
            />
          }
        />
        <CategoryCard
          title="Relics"
          link="/star-rail/guides/relics"
          image={
            <StaticImage
              src="../../images/starrail/categories/category_relicinfo.png"
              alt="Relics"
            />
          }
        />
        <CategoryCard
          title="Memory of Chaos Analytics"
          link="/star-rail/memory-of-chaos"
          image={
            <StaticImage
              src="../../images/starrail/categories/category_fh.png"
              alt="Memory of Chaos"
            />
          }
        />
        <CategoryCard
          title="Pure Fiction Analytics"
          link="/star-rail/pure-fiction"
          image={
            <StaticImage
              src="../../images/starrail/categories/category_pf.jpg"
              alt="Pure Fiction"
            />
          }
        />
        <CategoryCard
          title="Apocalyptic Shadow Analytics"
          link="/star-rail/apocalyptic-shadow"
          image={
            <StaticImage
              src="../../images/starrail/categories/category_apo.jpg"
              alt="Apocalyptic Shadow"
            />
          }
        />
      </Row>
      <SectionHeader title="Community" />
      <CommunitySection />
      <div className="fuse-ad-placeholder" data-fuse="22844297256"></div>
    </DashboardLayout>
  );
};

export default HSRIndexPage;

export const Head: React.FC = () => (
  <Seo
    title="Honkai: Star Rail Wiki and Database | Prydwen Institute"
    description="Prydwen.gg is a wiki and database for Honkai: Star Rail, a HoYoverse turn-based space fantasy RPG. Check out our guides, character reviews, tier list and more!"
  />
);
